import api from './api';


class LensService {
    async getLenses() {
        var lenses = {}
        const response = await api.get("/lenses");
  
        lenses = response.data;        
        
        return lenses;
    } 
    async getLens(lensAlias) {
        var lense_dict = {}
        const response = await api.get("/lens/"+lensAlias);
  
        lense_dict = response.data;        
        
        return lense_dict;
    } 
    async getPillars() {
        var pillars_dict = {}
        const response = await api.get("/pillars");
  
        pillars_dict = response.data;        
        
        return pillars_dict;
    } 
    async getLensVersionDifference(lensAlias, baseLensVersion, targetLensVersion) {
        const params = {
            base_lens_version: baseLensVersion,
            target_lens_version: targetLensVersion
        };
                  
        var lensVersionDifference = {}
        const response = await api.get("/lens/"+lensAlias+"/version_difference", { params });
  
        lensVersionDifference = response.data;        
        
        return lensVersionDifference;
    }

  }
  
  export default new LensService();