<template>
  <CCol>
    <CToaster :autohide="2000">
      <template>           
        <div v-if="showSuccessToast">
          <CToast          
          :show.sync="showSuccessToast"
          color="success"
          >
            {{ successToastMessage }}
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showErrorToast">
          <CToast          
          :show.sync="showErrorToast"
          color="danger"
          >
            {{ errorToastMessage }}
          </CToast>
        </div>   
      </template>
    </CToaster>     
    <CRow v-show="loading">
      <CCol col="10" style="max-width: 100rem;">
        <CCard v-show="loading">
          <CCardBody>
            <p class="text-center" v-show="loading">
              <CSpinner />
            </p >   
          </CCardBody>
        </CCard>
      </CCol>    
    </CRow>
  <CRow v-show="!loading"> 
    <CCol col="10" style="max-width: 100rem;">
        <CAlert color="primary" :closeButton="false" v-if="this.workloadsNotifications.length > 0">
          For {{ this.workloadsNotifications.length }} Workloads is a new Lens version available! 
        </CAlert>
      </CCol>
    <CCol col="12" xl="10" style="max-width: 100rem;">
      <CCard xl="4">
        <CCardHeader >
          <CButton class="float-left"  variant="ghost" color="dark" disabled>Workloads</CButton>
          <CButton class="ml-5 float-right"   @click="openHelp(true)" color="dark" :disabled="true"><span class="material-icons md-18">question_mark</span></CButton>
          <CButton class="ml-5 float-right col-sm-4 col-xl-2"  variant="outline" @click="createWorkload" color="success">Create Workload</CButton>          
          <CButton class="ml-5 float-right col-sm-4 col-xl-2"  variant="outline" @click="openWorkloadUpdates" color="primary" :disabled="this.workloadsNotifications.length == 0">View Updates</CButton>
        </CCardHeader>      
        <CCardBody>         
          <CDataTable
            hover
            striped
            sorter            
            column-filter
            items-per-page-select
            :sorter-value="{ column: 'UpdatedAt', asc: 'true' }"
            :items="workloads"
            :fields="fields"
            :items-per-page="20"      
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            :loading=loadingWorkloadsTable
          >                         
              
              <td slot="Lenses" slot-scope="{item}">
                <ul id="array-rendering">
                  <li :key="lens" v-for="lens in item.Lenses">
                    {{ getLensName(lens) }}
                  </li>
                </ul>
              </td> 
              <td slot="ImprovementStatus" slot-scope="{item}" >                  
                  {{ getStatus(item.ImprovementStatus) }}                  
              </td> 

              <td slot="UpdatedAt" slot-scope="{item}" >                  
                {{ convertDate(item.UpdatedAt) }}
              </td> 
               
              <td slot="workload_view" slot-scope="{item}">
                  <CButton v-on:click="openWorkload(item.WorkloadId)" :id="item.WorkloadId" block color="dark" style="display: inline-grid;">
                    <span class="material-icons md-18">search</span>

                  </CButton>
              </td>                  
              <td slot="workload_history_view" slot-scope="{item}">
                  <CButton v-on:click="viewWorkloadHistory(item)" :id="item.WorkloadId" block color="dark" style="display: inline-grid;">
                    <span class="material-icons md-18">query_stats</span>
                  </CButton>
              </td>           
              <td slot="customer_desc" slot-scope="{item}"> 
              <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                <span class="d-inline-block text-truncate" style="max-width: 250px;">
                  {{ item.customer_desc }}                  
                </span>  
                <div class="custom-tooltip" v-show="showTooltip">
                  {{ item.customer_desc }}                  
                </div>
              </div>        
            </td> 

            <td slot="workload_update" slot-scope="{item}" >
              <template v-if="isUpdateAvailableForWorkload(item.WorkloadId)">
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <CButton v-on:click="openWorkloadUpdate(item.WorkloadId)" :id="item.WorkloadId" block color="info" style="display: inline-grid;">
                    <span class="material-icons md-18">update</span>
                  </CButton>
                  <div class="custom-tooltip custom-tooltip-left" v-show="showTooltip">
                    Update for Workload Lenses available!
                  </div>
                </div>
              </template>
            </td>  
            <td slot="workload_delete" slot-scope="{item}">
              <CButton v-on:click="deleteWorkload(item.WorkloadId)" :id="item.workload_id" color="danger" style="display: flex;"> <span class="material-icons md-18">delete</span></CButton>
            </td>      

          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal
      :title="viewWorkloadItemName"
      color="dark"
      :show.sync="viewWorkloadHistoryModal"
      size="xl"
    >
        <p class="text-center" v-show="viewModalLoading">
          <CSpinner />
        </p >   
        <span v-show="!viewModalLoading">                
          <CCard>
            <CCardHeader>
              <strong>Risk Count History</strong>
            </CCardHeader>
         
            <CCardBody>  
              <CSelect
                style="width:150px"
                class="float-right"
                placeholder="Filter by..."
                :options="filteRiskHistoryBarChart"                
                disabled
                >
                </CSelect>                
              <CChartBar
                  style="height:400px"
                  :datasets="riskHistoryBarChartDataset"
                  :labels="riskHistoryBarChartLabels"
                  :options="{ maintainAspectRatio: false, scales: { xAxes: [{ stacked: true } ], yAxes: [{ stacked: true } ] } }"
                />        
            </CCardBody>    
          </CCard>
        </span>

      <div slot="footer" class="w-100">
          <CButton @click="viewWorkloadHistoryModal = false"  color="light" class="ml-1 mr-1 float-right col-sm-2" >Close</CButton>  
      </div>
    </CModal>    
    <!-- <CModal
      :title="viewWorkloadItemName"
      color="dark"
      :show.sync="viewModal"
      size="xl"
    >
        <p class="text-center" v-show="viewModalLoading">
          <CSpinner />
        </p >   
        <span v-show="!viewModalLoading">      
          <CCard>
              <CRow class="form-group" >
                <CCol :col="4">
                  <CCardHeader>
                    <strong>Review Owner</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ viewWorkloadItem.ReviewOwner }}
                  </CCardBody>                
                </CCol>
                <CCol :col="4">
                  <CCardHeader>
                    <strong>Workload Region</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ viewWorkloadItem.WorkloadRegion }}
                  </CCardBody>                
                </CCol>                
                <CCol :col="4">
                  <CCardHeader>
                    <strong>Updated</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ convertDate(viewWorkloadItem.UpdatedAt) }}
                  </CCardBody>
                </CCol>
              </CRow>
          </CCard>           
          <CCard>
            <CRow class="form-group" >

            <CCol :col="4">
                <CCardHeader>
                  <strong>Workload Name</strong>
                </CCardHeader>
                <CCardBody>
                  {{ viewWorkloadItem.FriendlyName }} / {{ viewWorkloadItem.WorkloadName }}
                </CCardBody>
              </CCol>                          
              <CCol :col="8">
                <CCardHeader>
                  <strong>Description</strong>
                </CCardHeader>
                <CCardBody>
                  {{ viewWorkloadItem.Description }}
                </CCardBody>
              </CCol>              
            </CRow>

          </CCard>
          <CCard>
              <CRow class="form-group" >
                <CCol :col="6">
                  <CCardHeader>
                    <strong>Environment</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ viewWorkloadItem.Environment }}
                  </CCardBody>                
                </CCol>
                <CCol :col="6">
                  <CCardHeader>
                  <strong>AWS Regions</strong>
                  </CCardHeader>
                  <CCardBody>
                    <ul id="array-rendering">
                      <li :key="region" v-for="region in viewWorkloadItem.AwsRegions">
                        {{ region }}
                      </li>
                    </ul>
                  </CCardBody>                
                </CCol>
              </CRow>
          </CCard>             
          <CCard>
              <CRow class="form-group" >
                <CCol :col="6">
                  <CCardHeader>
                    <strong>Lenses</strong>
                  </CCardHeader>
                  <CCardBody>
                    <ul id="array-rendering">
                      <li :key="lense" v-for="lense in viewWorkloadItem.Lenses">
                        {{ lenses[lense] }}
                      </li>
                    </ul>
                  </CCardBody>                
                </CCol>
                <CCol :col="6">
                  <CCardHeader>
                    <strong>Pillar Priorities</strong>
                  </CCardHeader>
                  <CCardBody>
                    <ul id="array-rendering">
                      <li :key="pillar" v-for="pillar in viewWorkloadItem.PillarPriorities">
                        {{ pillars[pillar] }}
                      </li>
                    </ul>
                  </CCardBody>                
                </CCol>
              </CRow>
          </CCard>   
          <CCard>
            <CCardHeader>
              <strong>Risk Count</strong>
            </CCardHeader>
            <CCardBody>
              <CChartPie
                  :datasets="riskPieDataset"
                  :labels="riskPieLabels"
                />        
            </CCardBody>                             
          </CCard>
        </span>

      <div slot="footer" class="w-100">
          <CButton @click="viewModal = false"  color="light" class="ml-1 mr-1 float-right col-sm-2" >Close</CButton>  
      </div>
    </CModal>     -->
  </CRow>
  </CCol>
</template>

<!-- TODO: Add customer to the table -->

<script>
import WorkloadService from '@/services/workload.services';
import LensService from '@/services/lense.services';
import moment from 'moment'
import { CChartPie, CChartBar } from '@coreui/vue-chartjs'


export default {
  components: { CChartPie, CChartBar },
  data() {
    return {
      showTooltip: false,      
      workloads: [],
      workloadsNotifications: [],
      fields: [
        { key: 'FriendlyName', label: 'Name', _style:'width:20%' },
        { key: 'Lenses', label: 'Lenses', _style:'width:30%' },
        { key: 'ImprovementStatus', label: 'Status', _style:'width:20%' },
        { key: 'WorkloadRegion', label: 'Region', _style:'width:10%' },
        { key: 'UpdatedAt', label: 'Last Change', _style:'width:20%' },
        { key: 'workload_view', label: '', _style: 'width:1%', sorter: false, filter: false },
        // { key: 'workload_edit', label: '', _style: 'width:1%', sorter: false, filter: false },
        { key: 'workload_history_view', label: '', _style: 'width:1%', sorter: false, filter: false },
        { key: 'workload_update', label: '', _style: 'width:1%', sorter: false, filter: false },
        { key: 'workload_delete', label: '', _style: 'width:1%', sorter: false, filter: false }

        
      ],
      lenses: [],
      loading: true,
      loadingWorkloadsTable: true,
      viewModal: false,
      viewWorkloadHistoryModal: false,
      viewModalLoading: true, 
      viewWorkloadItemName: "",
      viewWorkloadItem: {},
      activePage: 1,
      riskPieBackgroundColors: [
            '#1c7685',
            '#cc0000',
            '#ff9900',
            '#38761d',
            '#707272'
      ],
      riskPieLabels: [],
      riskPieDataset:  [],
      riskHistoryBarChartLabels: [],
      riskHistoryBarChartDataset:  [],
      filteRiskHistoryBarChart: [
        '1 Month',
        '3 Months',
        '6 Months',
        '12 Months'
      ],
      successToastMessage: '',
      errorToastMessage: '',
      showErrorToast: false,
      showSuccessToast: false,

      
    };
  },
  methods: {
    convertDate(date) {
      return moment(date).format('DD.MM.YYYY - H:mm:ss ')
    },    
    getStatus (status) {
      switch (status) {
        case 'NOT_APPLICABLE': return 'Not applicable'
        case 'NOT_STARTED': return 'Not started'
        case 'IN_PROGRESS': return 'In progress'
        case 'COMPLETE': return 'Complete'
        case 'RISK_ACKNOWLEDGED': return 'Risk acknowledged'
        default: 'Unknown'
      }
    }, 
    getRisk (risk) {
      switch (risk) {
        case 'HIGH': return 'High'
        case 'MEDIUM': return 'Medium'
        case 'NONE': return 'Well-Architected'
        case 'UNANSWERED': return 'Unanswered'
        case 'NOT_APPLICABLE': return 'Not applicable'
        default: 'Unanswered'
      }
    },       
    getLensName(lensAlias) {
      let lens = this.lenses.find(o => o.lens_alias === lensAlias);

      if (lens === undefined) {
        return lensAlias
      }

      return lens.lens_name;
    
    },
    isUpdateAvailableForWorkload(workloadId){
      let x = this.workloadsNotifications.find(o => o.lens_upgrade_summary.workload_id === workloadId);
      if (x != undefined) {
        return true;
      };
      return false;
    },
    openWorkloadUpdate(workloadId) {
      this.$router.push({path: `/workloads/${workloadId}/update`});
    },
    openWorkloadUpdates() {
      this.$router.push({path: '/workloads/updates'});
    },
    deleteWorkload(id) {
     this.$router.push({path: '/workloads/'+id+'/delete'});
    },    
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    createWorkload() {
      this.$router.push({path: '/workloads/create'});
    },    
    openWorkload(workload_id) {
      this.$router.push({path: `workloads/${workload_id}`})
    },    
    async viewWorkload(workload) {
      this.viewModalLoading = true;
      this.viewWorkloadItemName = workload.WorkloadName;
      this.viewModal = true;

      await WorkloadService.getWorkload(workload.WorkloadId, true).then(
        response => { 
          this.viewWorkloadItem = response
        },
        error => {   
          console.log(error)   
        }
      )
      var labels = [];
      var data = [];

      for (var key in this.viewWorkloadItem.RiskCounts) {
        labels.push(this.getRisk(key))
        data.push(this.viewWorkloadItem.RiskCounts[key])
      }

      this.riskPieDataset=[
        {
          backgroundColor: this.riskPieBackgroundColors,
          data: data
        }
      ];

      this.riskPieLabels=labels;
      this.viewModalLoading = false;
      
    },
    async viewWorkloadHistory(workload) {
      this.viewModalLoading = true;
      this.viewWorkloadItemName = workload.WorkloadName;
      this.viewWorkloadHistoryModal = true;

      await WorkloadService.getWorkloadHistory(workload.WorkloadId).then(
        response => { 
          this.viewWorkloadHistoryItem = response
        },
        error => {   
          console.log(error)   
        }
      );
      var labels = [];
      var data = {
        HIGH: [],
        MEDIUM: [],
        NONE: [],
        UNANSWERED: []
      };

      for (const [key, value] of this.viewWorkloadHistoryItem.Data.entries()) {      
        labels.push(this.convertDate(value.UpdatedAt))
        data.HIGH.push(value.RiskCounts.HIGH)
        data.MEDIUM.push(value.RiskCounts.MEDIUM)
        data.NONE.push(value.RiskCounts.NONE)
        data.UNANSWERED.push(value.RiskCounts.UNANSWERED)
      }

      this.riskHistoryBarChartDataset=[
        {
          data: data.HIGH,
          backgroundColor: '#cc0000',
          label: 'High',
        },
        {
          data: data.MEDIUM,
          backgroundColor: '#ff9900',
          label: 'Medium',
        },                  
        {
          data: data.NONE,
          backgroundColor: '#38761d',
          label: 'Well-Architcted',
        },
        {
          data: data.UNANSWERED,
          backgroundColor: '#707272',
          label: 'Unanswered',
        }
      ];

      this.riskHistoryBarChartLabels = labels;
      this.viewModalLoading = false;
      
    },    
  },

  created() { },
  async mounted() {
     await LensService.getLenses().then(
      response => { 
        this.lenses = response.lens_summaries;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Lenses. Please try again later.";
        this.showErrorToast = true;
      }      
    ) 
    await WorkloadService.getWorkloadsNotifications().then(
      response => { 
        this.workloadsNotifications = response.notifications;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Workloads Notifications. Please try again later.";
        this.showErrorToast = true;
      }      
    )
    WorkloadService.getWorkloads(true).then(
      response => { 
        this.workloads = response;
        this.loadingWorkloadsTable = false;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Workloads. Please try again later.";
        this.showErrorToast = true;
      }      
    )
    this.loading = false;
    
  },

  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>
